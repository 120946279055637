

<script>
import iframeMixin from '../iframeMixin.vue'

export default {
  mixins: [iframeMixin],
  computed: {
    url() {
     return '/ureport/preview?_u=mysql:daily.ureport.xml'
    },
  },
}
</script>
